.heroMask {
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-size: cover;
  -webkit-mask-size: cover;
}

@media screen and (min-width: 1024px) {
  .heroMask {
    -webkit-mask-image: url("/assets/graphics/hero-mask.background.svg");
    mask-image: url("/assets/graphics/hero-mask.background.svg");
  }
}

@media screen and (max-width: 1024px) {
  .heroMask {
    position: relative !important;
    -webkit-mask-image: url("/assets/graphics/hero-mobile-mask.background.svg");
    mask-image: url("/assets/graphics/hero-mobile-mask.background.svg");
    width: 100%;
    height: 100%;
    mask-repeat: no-repeat;
    mask-size: 100%;
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 100%;
  }
}
