.withBackground {
  position: relative;
  --backgroundPosition: ((100vw - 1280px) / 2);
}

.withBackground:before {
  content: "";
  display: block;
  z-index: 0;
  width: 100%;
  position: absolute;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1024px) {
  .withBackground:before {
    content: none;
  }
}

.latestArtBackground:before {
  background-image: url("../assets/graphics/backgrounds/latest-art.background.svg");
  background-size: 819px;
  background-position: left calc(var(--backgroundPosition) - 750px) top;
  height: 1219px;
  top: -130px;
  z-index: -1;
}

.featuredArtists:before {
  background-image: url("../assets/graphics/backgrounds/latest-art2.background.svg"),
    url("../assets/graphics/backgrounds/basic-endemic.background.svg");
  background-size: 716px, 1032px;
  background-position:
    top right calc(var(--backgroundPosition) - 750px),
    bottom 300px right calc(var(--backgroundPosition) - 750px);
  height: 100%;
  top: -300px;
}

.collections:before {
  box-sizing: content-box;
  background-image: url("../assets/graphics/backgrounds/basic-endemic.background.svg"),
    url("../assets/graphics/backgrounds/collections.background.svg");
  background-size: 1032px, 321px;
  background-position:
    top right calc(var(--backgroundPosition) - 750px),
    bottom left calc((var(--backgroundPosition) - 320px));
  top: -230px;
  bottom: -300px;
  z-index: -1;
}

.news:before {
  box-sizing: content-box;
  background-image: url("../assets/graphics/backgrounds/basic-endemic-revert.background.svg");
  background-size: 1032px, 321px;
  background-position: top left calc(var(--backgroundPosition) - 370px);
  top: -120px;
  bottom: -300px;
  z-index: -1;
}
